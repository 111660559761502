import React from "react"

import { Container, Row, Col } from "../Grid"
import Text from "../Text"
import Project from "../Project"

import * as S from "./styles"

const arrProjects = [
  {
    hrefDemo: "https://brunoflix.netlify.app/",
    hrefRepo: "https://github.com/BrunoSaibert/brunoflix",
    url: "brunoflix/brunoflix.jpg",
    title: "Brunoflix Web",
    tags: ["ReactJS", "TypeScript", "Styled components"],
    description: (
      <>
        <Text mb="15" color="cloud">
          O projeto tem a finalidade de desenvolver um clone da interface da
          Netflix utilizando uma API externa para listar os filmes e carregar o
          trailer.
        </Text>
      </>
    ),
  },
  {
    hrefDemo: "https://markeditor.netlify.app/",
    hrefRepo: "https://github.com/BrunoSaibert/markdown-editor",
    url: "markeditor/markdown.jpg",
    title: "Markdown Editor",
    tags: ["ReactJS", "Styled components", "Markdown"],
    description: (
      <>
        <Text mb="15" color="cloud">
          O projeto tem a finalidade de desenvolver um app de editor de textos
          em markdown com pré-visualização para facilitar a criação de arquivos
          no formato .md e arquivos README de projetos para o Github.
        </Text>
      </>
    ),
  },
  {
    hrefDemo: "https://mygithubexplorer.netlify.app/",
    hrefRepo: "https://github.com/BrunoSaibert/github_explorer",
    url: "github_explorer/git-explorer.jpg",
    title: "Github Explorer",
    tags: ["ReactJS", "Styled components", "TypeScript"],
    description: (
      <>
        <Text mb="15" color="cloud">
          A aplicação utiliza a api pública do Github e tem a finaidade de
          buscar e listar repositórios e outros usuários que podem localizados
          através de um campo de busca, a lista fica salva no localStorage do
          navegador. Também é possível ver os detalhes de dos repositórios e dos
          usuários adicionados.
        </Text>
      </>
    ),
  },
  {
    hrefDemo: "https://trebias-mememaker.netlify.app/",
    hrefRepo: "https://github.com/BrunoSaibert/mememaker",
    url: "mememaker/mememaker.jpg",
    title: "MemeMaker",
    tags: ["ReactJS", "Styled components"],
    description: (
      <>
        <Text mb="15" color="cloud">
          O projeto tem a finalidade de desenvolver um app para gerar memes
          utilizando uma API externa.
        </Text>
      </>
    ),
  },
]

export default function ListProjects() {
  return (
    <S.ListProjects>
      <Container>
        <Row alignItems="center">
          <Col size="12">
            {arrProjects.map((project, index) => (
              <Project key={index} {...project} />
            ))}
          </Col>
        </Row>
      </Container>
    </S.ListProjects>
  )
}
