import styled from "styled-components"

export const ListProjects = styled.div`
  padding: 50px 0;
  margin: 10px 0;

  @media (max-width: ${props => props.theme.grid.breakpoint.md}px) {
    padding: 0;
  }
`
