import styled from "styled-components"

export const TextMidia = styled.section`
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  background: ${props => props.theme.color[props.bgColor || "light"]};
`

export const Title = styled.p`
  color: ${props => props.theme.color[props.color || "shadow_dark"]};
  text-align: ${props => props.align || "left"};
  margin-bottom: 20px;
`

export const Description = styled.p`
  color: ${props => props.theme.color[props.color || "shadow_dark"]};
  text-align: ${props => props.align || "left"};
  font-weight: ${props => props.theme.font.weight.regular};
`

export const Img = styled.img`
  ${props => props.fluid && `max-width: 100%; display: block;`}
`
