import React from "react"

import Layout from "../components/Layout"

import Intro from "../components/Intro"
import TextMidia from "../components/TextMidia"
import ListProjects from "../components/ListProjects"

import Icon from "../images/undraw_master_plan_95wa.svg"

import SEO from "../components/seo"

const ProjetosPage = () => (
  <Layout>
    <SEO title="Projetos | Bruno Saibert - React Front-End Developer" />

    <Intro
      full={false}
      title="Veja alguns dos projetos que desenvolvi"
      img={{
        src: Icon,
        alt: "",
      }}
    />

    <ListProjects />
  </Layout>
)

export default ProjetosPage
